import { getGAClientIdFromGTM } from "@product/scmp-sdk";
import Cookies from "js-cookie";
import { config } from "shared/data";
const getGAIdFromCookie = () => {
  const gaValue = Cookies.get("_ga");
  if (!gaValue) return null;

  // GA cookie format: GA1.1.1234567890.1234567890
  const parts = gaValue.split(".");
  if (parts.length >= 2) {
    return parts.slice(-2).join(".");
  }
  return null;
};
export const getGoogleAnalyticsClientIdSync = () => {
  var _ga, _ga$getAll;
  if (typeof ga === "undefined") return;
  const instance = (_ga = ga) === null || _ga === void 0 || (_ga$getAll = _ga.getAll) === null || _ga$getAll === void 0 || (_ga$getAll = _ga$getAll.call(_ga)) === null || _ga$getAll === void 0 ? void 0 : _ga$getAll[0];
  if (!instance) return;
  return instance.get("clientId");
};
export const getGoogleAnalyticsClientId = async () => getGoogleAnalyticsClientIdSync() ?? (await getGAClientIdFromGTM(config.googleAnalytics.measurementId)) ?? getGAIdFromCookie() ?? "";