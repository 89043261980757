import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync, useDeepCompareEffect, useMountEffect } from "@react-hookz/web";
import defaults from "lodash/defaults";
import { useState } from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import type { DeepRequired } from "utility-types";

import { config as configData } from "shared/data";

import type { hooksAdvertisementCampaignConfigQuery } from "scmp-app/queries/__generated__/hooksAdvertisementCampaignConfigQuery.graphql";

export const useAdvertisementCampaignConfig = () => {
  const { set: setCachedConfig, value: cachedConfig } =
    useFallbackStorageValue<RawAdvertisementCampaignConfig>("advertisement-campaign-config");

  const [config, setConfig] = useState<AdvertisementCampaignConfig>(
    parseCampaignConfig(cachedConfig) ?? defaultAdvertisementCampaignConfig,
  );
  const environment = useRelayEnvironment();
  const [{ result: remoteConfig, status }, { execute }] = useAsync(async () => {
    const data = await fetchQuery<hooksAdvertisementCampaignConfigQuery>(
      environment,
      graphql`
        query hooksAdvertisementCampaignConfigQuery($id: String) {
          appConfig(filter: { entityId: $id }) {
            json
          }
        }
      `,
      {
        id: configData.advertisement.campaignConfigId,
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    return data.appConfig.json as RawAdvertisementCampaignConfig;
  });
  useMountEffect(execute);

  useDeepCompareEffect(() => {
    if (!remoteConfig) return;

    setConfig(parseCampaignConfig(remoteConfig) ?? defaultAdvertisementCampaignConfig);
    setCachedConfig(remoteConfig);
  }, [remoteConfig, setCachedConfig]);

  return { config, loaded: status !== "loading" && status !== "not-executed" };
};

export type AdvertisementCampaignConfig = {
  autoRefresh: {
    disabledDirectCampaignLineItemIds: number[];
  };
  takeoverAd: {
    homepage: AdvertisementTakeoverAdSectionsConfig;
    style: AdvertisementTakeoverAdSectionsConfig;
  };
};

export const defaultAdvertisementCampaignConfig: AdvertisementCampaignConfig = {
  autoRefresh: {
    disabledDirectCampaignLineItemIds: [],
  },
  takeoverAd: {
    homepage: {
      country: [],
      enableInPageTypes: {
        article: {
          enable: false,
        },
        mainSection: {
          enable: false,
        },
        subSection: {
          enable: false,
        },
      },
      enableTakeoverByDateTime: false,
      endDateTime: 0,
      ipAddress: [],
      startDateTime: 0,
      takeoverAlwaysEnable: false,
      takeoverEdition: [],
      targetNonSubscribersOnly: false,
      targetSubscribersOnly: false,
    },
    style: {
      country: [],
      enableInPageTypes: {
        article: {
          enable: false,
        },
        mainSection: {
          enable: false,
        },
        subSection: {
          enable: false,
        },
      },
      enableTakeoverByDateTime: false,
      endDateTime: 0,
      ipAddress: [],
      startDateTime: 0,
      takeoverAlwaysEnable: false,
      takeoverEdition: [],
      targetNonSubscribersOnly: false,
      targetSubscribersOnly: false,
    },
  },
};

export type AdvertisementTakeoverAdSectionsConfig =
  DeepRequired<RawAdvertisementTakeoverAdSectionConfig>;

type RawAdvertisementCampaignConfig = {
  directCampaignLineItemIds?: number[];
  takeoverAd?: {
    homepage?: RawAdvertisementTakeoverAdSectionConfig;
    style?: RawAdvertisementTakeoverAdSectionConfig;
  };
};

type RawAdvertisementTakeoverAdSectionConfig = {
  country?: string[];
  enableInPageTypes?: {
    article?: {
      enable?: boolean;
    };
    mainSection: {
      enable?: boolean;
    };
    subSection: {
      enable?: boolean;
    };
  };
  enableTakeoverByDateTime: boolean;
  endDateTime: number;
  ipAddress?: string[];
  startDateTime: number;
  takeoverAlwaysEnable: boolean;
  takeoverEdition?: string[];
  targetNonSubscribersOnly?: boolean;
  targetSubscribersOnly?: boolean;
};

function parseCampaignConfig(
  raw?: null | RawAdvertisementCampaignConfig,
): AdvertisementCampaignConfig | undefined {
  if (!raw) return;

  const parsed: DeepPartial<AdvertisementCampaignConfig> = {
    autoRefresh: {
      disabledDirectCampaignLineItemIds: raw.directCampaignLineItemIds,
    },
    takeoverAd: {
      homepage: {
        country: raw.takeoverAd?.homepage?.country ?? [],
        enableTakeoverByDateTime: raw.takeoverAd?.homepage?.enableTakeoverByDateTime,
        endDateTime: raw.takeoverAd?.homepage?.endDateTime,
        ipAddress: raw.takeoverAd?.homepage?.ipAddress,
        startDateTime: raw.takeoverAd?.homepage?.startDateTime,
        takeoverAlwaysEnable: raw.takeoverAd?.homepage?.takeoverAlwaysEnable,
        takeoverEdition: raw.takeoverAd?.homepage?.takeoverEdition,
        targetNonSubscribersOnly: raw.takeoverAd?.homepage?.targetNonSubscribersOnly,
        targetSubscribersOnly: raw.takeoverAd?.homepage?.targetSubscribersOnly,
      },
      style: {
        country: raw.takeoverAd?.style?.country ?? [],
        enableInPageTypes: {
          article: {
            enable: !!raw?.takeoverAd?.style?.enableInPageTypes?.article?.enable,
          },
          mainSection: {
            enable: !!raw?.takeoverAd?.style?.enableInPageTypes?.mainSection?.enable,
          },
          subSection: {
            enable: !!raw?.takeoverAd?.style?.enableInPageTypes?.subSection?.enable,
          },
        },
        enableTakeoverByDateTime: raw.takeoverAd?.style?.enableTakeoverByDateTime,
        endDateTime: raw.takeoverAd?.style?.endDateTime,
        ipAddress: raw.takeoverAd?.style?.ipAddress,
        startDateTime: raw.takeoverAd?.style?.startDateTime,
        takeoverAlwaysEnable: raw.takeoverAd?.style?.takeoverAlwaysEnable,
        targetNonSubscribersOnly: raw.takeoverAd?.style?.targetNonSubscribersOnly,
        targetSubscribersOnly: raw.takeoverAd?.style?.targetSubscribersOnly,
      },
    },
  };

  const merged = {
    autoRefresh: defaults(parsed.autoRefresh, defaultAdvertisementCampaignConfig.autoRefresh),
    takeoverAd: {
      homepage: defaults(
        parsed.takeoverAd?.homepage,
        defaultAdvertisementCampaignConfig.takeoverAd.homepage,
      ),
      style: defaults(
        parsed.takeoverAd?.style,
        defaultAdvertisementCampaignConfig.takeoverAd.style,
      ),
    },
  };

  return merged;
}
