/**
 * @generated SignedSource<<23268a5279deb73636b5cf7b6eafe5a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type hooksAdvertisementCampaignConfigQuery$variables = {
  id?: string | null | undefined;
};
export type hooksAdvertisementCampaignConfigQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
};
export type hooksAdvertisementCampaignConfigQuery = {
  response: hooksAdvertisementCampaignConfigQuery$data;
  variables: hooksAdvertisementCampaignConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entityId",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "json",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksAdvertisementCampaignConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hooksAdvertisementCampaignConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0fe1870f1ebd276b04bdd463b763c9ed",
    "id": null,
    "metadata": {},
    "name": "hooksAdvertisementCampaignConfigQuery",
    "operationKind": "query",
    "text": "query hooksAdvertisementCampaignConfigQuery(\n  $id: String\n) {\n  appConfig(filter: {entityId: $id}) {\n    json\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4daf479e714365c6354a579309413751";

export default node;
